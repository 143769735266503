import { Route } from '@angular/router';
import { signInWithTokenGuard } from './auth/auth-token/sign-in-with-token.guard';
import { loggedInGuard } from './auth/logged-in.guard';
import { needPermissionGuard } from './auth/permissions/need-permission.guard';

export const routes: Route[] = [
  {
    path: '',
    canActivate: [loggedInGuard],
    loadComponent: () =>
      import('./layout/main-layout/main-layout.component').then(
        (m) => m.MainLayoutComponent,
      ),
    children: [
      {
        path: '',
        pathMatch: 'full',
        title: 'Accueil',
        loadComponent: () =>
          import('./home/home.component').then((m) => m.HomeComponent),
        data: {
          shrinkHeaderOnScroll: true,
        },
      },
      {
        path: 'decisions',
        loadChildren: () => import('./court-decision/court-decision.routes'),
      },
      {
        path: 'detect',
        canActivate: [needPermissionGuard('access-detect')],
        loadChildren: () => import('./detect/detect.routes'),
      },
      {
        path: 'codes-et-lois',
        loadChildren: () => import('./law-article/law-article.routes'),
      },
      {
        path: 'entreprises',
        loadChildren: () => import('./company/company.routes'),
      },
      {
        path: 'dirigeants',
        loadChildren: () => import('./leaders/leader.routes'),
      },
      {
        path: 'actualites',
        loadChildren: () => import('./news/news.routes'),
      },
      {
        path: 'compte',
        loadChildren: () => import('./account/account.routes'),
        canActivate: [needPermissionGuard('access-account')],
      },
      {
        path: 'documents',
        canActivate: [needPermissionGuard('access-documents')],
        loadChildren: () => import('./folders/folders.routes'),
      },
      {
        path: 'historique',
        canActivate: [needPermissionGuard('access-search-history')],
        loadChildren: () =>
          import('./search/search-history/search-history.routes'),
      },
      {
        path: 'veille-jurisprudentielle',
        canActivate: [needPermissionGuard('access-alerts')],
        loadChildren: () => import('./alert/alert.routes'),
      },
      {
        path: 'contact',
        title: 'Contacts',
        loadComponent: () =>
          import('./contact/contact.component').then((m) => m.ContactComponent),
      },
    ],
  },
  {
    path: 'connexion',
    title: 'Connexion',
    loadComponent: () =>
      import('./auth/sign-in/sign-in.component').then((m) => m.SignInComponent),
    canActivate: [signInWithTokenGuard],
  },
  {
    path: 'inscription/:token',
    title: 'Inscription',
    loadComponent: () =>
      import('./auth/sign-up-page/sign-up-page.component').then(
        (m) => m.SignUpPageComponent,
      ),
  },
  { path: '**', redirectTo: '/' },
];
