import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, computed, inject } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  catchError,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../account/account.service';
import { ApiError } from '../../core/api/api-error';
import { ApiErrorCode } from '../../core/api/api-error-code';
import { ApiErrorSnackBarComponent } from '../../core/api/api-error-snack-bar/api-error-snack-bar.component';
import { WINDOW } from '../../core/window';
import { AuthTokenService } from '../auth-token/auth-token.service';
import { SignUpData } from '../auth.service';
import { LegacyUser } from '../legacy/legacy-user';
import { UserPermission } from '../permissions/user-permissions';
import { User } from '../user';
import { LegacyUserAdapterService } from './legacy-user-adapter.service';

export interface LegacyLoginResult {
  token: string;
  user: LegacyUser;
}

export interface SignUpResult {
  error: false;
  message: '';
  user: { public: LegacyUser };
}

export interface SignUpError {
  error: true;
  message: string;
  user: null;
}

@Injectable({
  providedIn: 'root',
})
export class LegacyAuthService {
  private readonly authToken = inject(AuthTokenService);
  private readonly router = inject(Router);
  private readonly window = inject(WINDOW);
  private readonly http = inject(HttpClient);
  private readonly accountDataClient = inject(AccountService);
  private readonly userAdapter = inject(LegacyUserAdapterService);
  private readonly snackBar = inject(MatSnackBar);

  private readonly refreshUserSubject = new Subject<void>();

  readonly isAuthenticated = computed(() => !!this.authToken.value());
  readonly user = toSignal(
    merge(
      this.accountDataClient.updated,
      this.refreshUserSubject,
      toObservable(this.authToken.value),
    ).pipe(switchMap(() => this.getCurrentUser().pipe(startWith(undefined)))),
  );

  getCurrentUser(): Observable<User | null> {
    if (!this.isAuthenticated()) {
      return of(null);
    }
    return this.http.get<LegacyUser>(`${environment.apiUrl}/user/profile`).pipe(
      map((user) => this.userAdapter.format(user)),
      catchError(() => of(null)),
    );
  }

  currentUserHasPermission(permission: UserPermission): Signal<boolean> {
    return computed(() => {
      const user = this.user();
      return !!user && user.permissions.includes(permission);
    });
  }

  currentUserHasSomePermissions(
    permissions: UserPermission[],
  ): Signal<boolean> {
    return computed(() => {
      const hasSomePermissions = permissions.some((permission) =>
        this.currentUserHasPermission(permission)(),
      );
      return permissions.length === 0 || hasSomePermissions;
    });
  }

  checkAccount(code: string): Observable<void> {
    return this.http
      .post<{ code_verified: boolean }>(`${environment.apiUrl}/me/code`, {
        code,
      })
      .pipe(
        switchMap((result) =>
          result.code_verified
            ? of(undefined)
            : throwError(() => ({
                code: ApiErrorCode.VerificationCodeNotMatching,
                message: 'No verification code matching',
              })),
        ),
        tap({
          next: () => {
            this.refreshUserSubject.next();
          },
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  signIn(
    email: string,
    password: string,
    cloudflareTurnstileToken: string,
  ): Observable<string> {
    return this.http
      .post<LegacyLoginResult>(`${environment.apiUrl}/login`, {
        login: email,
        password,
        cloudflareTurnstileToken,
      })
      .pipe(
        map(({ token }) => {
          this.authToken.value.set(token);
          return token;
        }),
      );
  }

  signInWithToken(token: string): void {
    this.authToken.value.set(token);
  }

  signInByIPAddress(): Observable<string | null> {
    return this.http
      .get<LegacyLoginResult | false>(`${environment.apiUrl}/ipauth`)
      .pipe(
        map((result) => {
          if (result) {
            this.authToken.value.set(result.token);
            return result.token;
          }
          return null;
        }),
      );
  }

  signUp(data: SignUpData): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/registration`, data);
  }

  askForNewPassword(email: string): Observable<void> {
    return this.http
      .post<boolean>(`${environment.apiUrl}/login/reminder`, {
        email,
      })
      .pipe(
        switchMap((accepted) =>
          accepted
            ? of(undefined)
            : throwError(() => ({
                code: 'NO_ACCOUNT_ASSOCIATED',
                message: 'There is no account associated to this email address',
              })),
        ),
        tap({
          error: (error: ApiError) => {
            this.snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          },
        }),
      );
  }

  logout(): void {
    const redirectionUrl = '/connexion';

    this.authToken.value.set(null);
    if (this.window === this.window.top) {
      this.router.navigateByUrl(redirectionUrl);
    } else {
      this.window.open(redirectionUrl, '_top');
    }
  }
}
