import { Injectable } from '@angular/core';
import { LawArticle, LawArticleVersion } from '../law-article';
import { createLawArticleTitle } from '../law-article-helper';
import { LawArticleSearchResult } from '../law-article.service';
import {
  LegacyLawArticle,
  LegacyLawArticleVersion,
} from './legacy-law-article';
import {
  ARTICLE_CODE_INFINITY_DATE,
  LegacyLawArticleSearchResult,
} from './legacy-law-article.service';

@Injectable({
  providedIn: 'root',
})
export class LegacyLawArticleAdapterService {
  formatSearchResult(
    searchResult: LegacyLawArticleSearchResult,
  ): LawArticleSearchResult {
    const lawArticleNumber =
      searchResult.number && this.formatNumber(searchResult.number);

    return {
      id: searchResult.id,
      title: createLawArticleTitle(searchResult.code.name, searchResult.number),
      status: searchResult.status,
      date: {
        start: searchResult.start_date,
        end:
          searchResult.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? searchResult.end_date
            : null,
      },
      articleNumber: lawArticleNumber,
      legalCode: {
        name: searchResult.code.name,
      },
      highlight: searchResult.extrait,
      summary: searchResult.resume,
    };
  }

  format(lawArticle: LegacyLawArticle): LawArticle {
    const lawArticleNumber =
      lawArticle.number && this.formatNumber(lawArticle.number);

    return {
      id: lawArticle.id,
      title: createLawArticleTitle(lawArticle.code.name, lawArticle.number),
      status: lawArticle.status,
      date: {
        start: lawArticle.start_date,
        end:
          lawArticle.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? lawArticle.end_date
            : null,
      },
      articleNumber: lawArticleNumber,
      legalCode: {
        name: lawArticle.code.name,
      },
      content: lawArticle.content,
      versions: lawArticle.versions.map((version) =>
        this.formatVersion(version),
      ),
      summary: lawArticle.resume,
      highlight: lawArticle.extrait,
    };
  }

  private formatVersion(version: LegacyLawArticleVersion): LawArticleVersion {
    return {
      id: version.id,
      status: version.status,
      date: {
        start: version.start_date,
        end:
          version.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? version.end_date
            : null,
      },
    };
  }

  private formatNumber(value: string): string {
    return value.replace(/^([LRDA])(\d+)/, '$1.$2');
  }
}
