import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LawArticle } from '../law-article';
import {
  LawArticleParams,
  LawArticleSearchResults,
} from '../law-article.service';
import { LegacyLawArticle } from './legacy-law-article';
import { LegacyLawArticleAdapterService } from './legacy-law-article-adapter.service';

export type LegacyLawArticleSort = 'SCORE' | 'DATE';

export type LegacyLawArticleSearchResult = Omit<
  LegacyLawArticle,
  'content' | 'versions'
>;

export interface LegacyLawArticleSearchResultLegalCode {
  code: string;
  count: number;
}

export interface LegacyLawArticleSearchResults {
  suggestions: string;
  search_suggestion: string;
  resultats: LegacyLawArticleSearchResult[];
  codes: LegacyLawArticleSearchResultLegalCode[];
  duration: number;
  numres: number;
  translated_search: string;
}

/**
 * Arbitrary values from API PHP that allows
 * to determine the article code effective status.
 * So yeah, the end of the world is scheduled for 2999.
 */
export const ARTICLE_CODE_INFINITY_DATE = '2999-01-01';

@Injectable({
  providedIn: 'root',
})
export class LegacyLawArticleService {
  private readonly http = inject(HttpClient);
  private readonly lawArticleAdapter = inject(LegacyLawArticleAdapterService);

  all(params?: LawArticleParams): Observable<LawArticleSearchResults> {
    let httpParams = new HttpParams({
      fromObject: {
        jurisprudence: 'article_code_doc',
      },
    });

    if (params?.pageIndex !== undefined && params?.pageSize !== undefined) {
      httpParams = httpParams.set('offset', params.pageIndex * params.pageSize);
    }
    if (params?.pageSize !== undefined) {
      httpParams = httpParams.set('nb_res', params.pageSize);
    }
    if (params?.filters?.query) {
      httpParams = httpParams.set('free_text', params.filters.query);
    }
    if (params?.sort) {
      httpParams = httpParams.set('sort', params.sort);
    }
    if (params?.filters?.date?.start?.isValid) {
      httpParams = httpParams.set(
        'date_debut',
        params.filters.date.start.toISODate(),
      );
    }
    if (params?.filters?.date?.end?.isValid) {
      httpParams = httpParams.set(
        'date_fin',
        params.filters.date.end.toISODate(),
      );
    }
    if (params?.filters?.legalCode) {
      httpParams = httpParams.set('filter_by_code', params.filters.legalCode);
    }

    return this.http
      .get<LegacyLawArticleSearchResults>(`${environment.apiUrl}/search`, {
        params: httpParams,
      })
      .pipe(
        map((data) => ({
          data: data.resultats.map((item) =>
            this.lawArticleAdapter.formatSearchResult(item),
          ),
          duration: data.duration,
          total: data.numres,
        })),
      );
  }

  find(id: string): Observable<LawArticle> {
    return this.http
      .get<LegacyLawArticle>(`${environment.apiUrl}/code/article/${id}`)
      .pipe(map((lawArticle) => this.lawArticleAdapter.format(lawArticle)));
  }
}
