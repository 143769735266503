import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ApiError } from './api-error';
import { ApiErrorCode } from './api-error-code';
import { ApiErrorSnackBarComponent } from './api-error-snack-bar/api-error-snack-bar.component';
import {
  isApiError,
  isApiForbiddenErrorResponse,
  isApiGatewayRequest,
  isApiRequest,
  isApiUnauthorizedErrorResponse,
} from './api-helper';

export const apiErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  const snackBar = inject(MatSnackBar);
  const router = inject(Router);

  if (!isApiRequest(req) && !isApiGatewayRequest(req)) {
    return next(req);
  }

  return next(req).pipe(
    tap({
      error: (res: HttpErrorResponse) => {
        if (isApiUnauthorizedErrorResponse(res)) {
          if (res.url === `${environment.apiUrl}/login`) {
            const error: ApiError = {
              code: ApiErrorCode.InvalidCredentials,
              message: 'Invalid credentials',
            };
            snackBar.openFromComponent(ApiErrorSnackBarComponent, {
              data: error,
              duration: 6000,
              panelClass: 'jrp-error-snack-bar',
            });
          } else {
            auth.logout();
          }
        } else if (isApiForbiddenErrorResponse(res)) {
          router.navigate(['/compte']);
        } else if (isApiError(res.error)) {
          snackBar.openFromComponent(ApiErrorSnackBarComponent, {
            data: res.error,
            duration: 6000,
            panelClass: 'jrp-error-snack-bar',
          });
        }
      },
    }),
  );
};
