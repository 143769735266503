import { UserPermission } from './permissions/user-permissions';

export type UserStatus =
  | 'PAID'
  | 'TRIAL'
  | 'PENDING'
  | 'GRACEPERIOD'
  | 'EXPIRED';

export const ACTIVE_STATUSES: UserStatus[] = ['PAID', 'TRIAL', 'GRACEPERIOD'];

export type UserType = 'Lawyer' | 'Non-lawyer';

export interface User {
  id: number;
  type: UserType;
  fullname: string;
  email: string;
  phone: string | null;
  newsletterConfig: UserNewsletterConfig;
  verified: boolean;
  expireAt: string | null;
  permissions: UserPermission[];
  status: UserStatus;
  category: string;
  isLegacy: boolean;
}

export interface UserNewsletterConfig {
  days: number[];
  newsCategoryIds: number[];
  hour: number;
}

export interface UserGdprInfos {
  accepted: boolean;
  label: string;
}

export interface LaywerOffice {
  email: string | null;
  fax: string | null;
  phone: string | null;
  zipCode: string | null;
  city: string | null;
  address: string | null;
}
