import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { first, map, skipWhile } from 'rxjs';
import { AuthService } from '../auth.service';
import { UserPermission } from './user-permissions';

export const needPermissionGuard =
  (permission: UserPermission): CanActivateFn =>
  () => {
    const router = inject(Router);
    const auth = inject(AuthService);

    return toObservable(auth.user).pipe(
      skipWhile((user) => user === undefined),
      first(),
      map(
        (user) =>
          user?.permissions.includes(permission) || router.parseUrl('/'),
      ),
    );
  };
