import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UserPermission } from '../permissions/user-permissions';
import { ACTIVE_STATUSES, User } from '../user';
import { LegacyUser } from './legacy-user';

@Injectable({
  providedIn: 'root',
})
export class LegacyUserAdapterService {
  format(user: LegacyUser): User {
    return {
      id: user.id,
      type: user.is_lawyer ? 'Lawyer' : 'Non-lawyer',
      fullname: user.nom,
      email: user.email,
      phone: user.mobile,
      newsletterConfig: {
        days: Object.values(user.newsletter_config.days)
          .filter((value) => value !== '')
          .map(Number),
        newsCategoryIds: Object.values(
          user.newsletter_config.topics_selected,
        ).map(Number),
        hour: Number(user.newsletter_config.hour),
      },
      verified: Boolean(user.is_verified),
      expireAt:
        user.expiration &&
        DateTime.fromSQL(user.expiration, {
          zone: 'Europe/Paris',
        }).toISO(),
      permissions: this.getLegacyUserPermissions(user),
      status: user.statut_paiement,
      category: user.profile,
      isLegacy: user.isLegacy ?? true,
    };
  }

  private getLegacyUserPermissions(user: LegacyUser): UserPermission[] {
    const isActive = ACTIVE_STATUSES.includes(user.statut_paiement);
    const useProxy = user.is_proxy_access;
    let permissions: UserPermission[] = [];

    if (!useProxy) {
      permissions = permissions.concat('access-account');

      if (isActive) {
        permissions = permissions.concat(
          'access-detect',
          'access-documents',
          'access-search-history',
          'access-alerts',
          'access-newsletter-configuration',
        );
      }
    }
    return permissions;
  }
}
