import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiError } from './api-error';

export function isApiRequest(request: HttpRequest<unknown>): boolean {
  return request.url.startsWith(environment.apiUrl);
}

export function isApiGatewayRequest(request: HttpRequest<unknown>): boolean {
  return request.url.startsWith(environment.apiGatewayUrl);
}

export function isApiError(error: Partial<ApiError>): error is ApiError {
  return Boolean(error && error.code && error.message);
}

export function isApiUnauthorizedErrorResponse(
  error: HttpErrorResponse,
): boolean {
  return (
    error.status === 401 && error.url !== `${environment.apiUrl}/registration`
  );
}

export function isApiForbiddenErrorResponse(error: HttpErrorResponse): boolean {
  return error.status === 403;
}
