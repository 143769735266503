import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { LegacyAccountService } from './legacy/legacy-account.service';

export interface AccountUpdateData {
  fullname?: string;
}

export interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UpdateAccountNewsletterConfigData {
  newsCategoryIds: number[];
  days: number[];
  hour: number;
}

@Injectable({
  providedIn: 'root',
  useExisting: LegacyAccountService,
})
export class AccountService {
  private readonly http = inject(HttpClient);

  private readonly updatedSubject = new Subject<void>();
  readonly updated = this.updatedSubject.asObservable();

  update(data: AccountUpdateData): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        nom: data.fullname,
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }

  changePassword(data: ChangePasswordData): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user/password`, {
      oldPass: data.currentPassword,
      newPass: data.newPassword,
      newPassConfirm: data.confirmNewPassword,
    });
  }

  updateNewsletterConfig(
    data: UpdateAccountNewsletterConfigData,
  ): Observable<void> {
    return this.http
      .post<void>(`${environment.apiUrl}/user/profile`, {
        newsletter_config: {
          topics_selected: Object.fromEntries(
            data.newsCategoryIds.map((id) => [id, id.toString()]),
          ),
          days: Object.fromEntries(
            data.days.map((day) => [day, day.toString()]),
          ),
          hour: data.hour,
        },
      })
      .pipe(tap(() => this.updatedSubject.next()));
  }
}
